import React from "react"
import ImageLoader from "../components/imageLoader"
import Layout from "../components/layout"
import PageLink from "../components/pageLink"
import Seo from "gatsby-plugin-wpgraphql-seo"

function Client({ pageContext }) {
  const { pageContent } = pageContext

  console.log(pageContent)

  return (
    <Layout
      title={pageContent.title}
      subTitle={pageContent.clientDetails.services}
    >
      <Seo post={pageContent} />
      <div className="mx-auto max-w-screen-2xl">
        <div className="hidden lg:grid lg:grid-cols-3 border-black border-b-2 border-l-2 border-r-2">
          {pageContent.clientDetails.topImages &&
            pageContent.clientDetails.topImages.map(item => {
              return (
                <ImageLoader
                  image={item}
                  // className="border-black border-r-2"
                  objectFit="cover"
                  imgStyle={{ objectFit: "cover" }}
                  className="object-cover h-full w-full"
                />
              )
            })}
        </div>
        <div className="lg:grid lg:grid-cols-2">
          {pageContent.clientDetails.leftImage ? (
            <div className="border-black border-2 border-t-0">
              <ImageLoader
                image={pageContent.clientDetails.leftImage}
                // className="border-black border-r-2"
                objectFit="cover"
                imgStyle={{ objectFit: "cover" }}
                className="object-cover h-full w-full"
              />
            </div>
          ) : (
            ""
          )}
          {pageContent.clientDetails.aboutTheClient &&
          pageContent.clientDetails.aboutTheClient.trim().length > 0 ? (
            <div className="p-8 lg:p-12 flex flex-col justify-center">
              <div className="uppercase text-xl mb-4 lg:mb-8">
                About the Client
              </div>
              <div className="text-justify whitespace-pre-wrap">
                {pageContent.clientDetails.aboutTheClient}
              </div>
              <div className="my-5 text-right">
                <PageLink url={pageContent.clientDetails.clientUrl}>
                  see more of{" "}
                  <span className="uppercase">{pageContent.title}</span>
                </PageLink>
              </div>
            </div>
          ) : (
            ""
          )}
          {pageContent.clientDetails.topImages.map((item, i) => {
            return (
              <div
                className={
                  "border-black border-l-2 border-r-2 lg:hidden border-b-2 " +
                  (i < 1 ? "border-t-2" : "")
                }
              >
                <ImageLoader
                  image={pageContent.clientDetails.topImages[i]}
                  // className="border-black border-r-2"
                  objectFit="cover"
                  imgStyle={{ objectFit: "cover" }}
                  className="object-cover h-full w-full"
                />
              </div>
            )
          })}

          {pageContent.clientDetails.aboutTheJob &&
          pageContent.clientDetails.aboutTheJob.trim().length > 0 ? (
            <div className="p-8 lg:p-12 flex flex-col justify-center">
              <div className="uppercase text-xl mb-4 lg:mb-8">
                About the Job
              </div>
              <div className="text-justify whitespace-pre-wrap">
                {pageContent.clientDetails.aboutTheJob}
              </div>
              <div className="my-5 text-right">
                <PageLink url="/our-work">
                  see more of <span className="uppercase">OUR WORK</span>
                </PageLink>
              </div>
            </div>
          ) : (
            ""
          )}
          {pageContent.clientDetails.rightImage ? (
            <div
              className={
                "border-black border-2 " +
                (pageContent.clientDetails.aboutTheJob &&
                pageContent.clientDetails.aboutTheJob.trim().length > 0
                  ? ""
                  : "lg:border-l-0 lg:border-t-0")
              }
            >
              <ImageLoader
                image={pageContent.clientDetails.rightImage}
                // className="border-black border-r-2"
                objectFit="cover"
                imgStyle={{ objectFit: "cover" }}
                className="object-cover h-full w-full"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <div>Page info!</div> */}
    </Layout>
  )
}

export default Client
